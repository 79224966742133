import React from "react";
import { useStaticQuery } from "gatsby";
import RichTextRenderer from "../utils/richTextRenderer";

import PageHeader from "../components/pageHeader";
import Layout from "../components/layout";
import SEO from "../components/seo";

const CyfhWorldDay = ({ location }) => {
  const { cyfhWorldDay } = useStaticQuery(
    graphql`
      query {
        cyfhWorldDay: contentfulCyFhWorldDay {
          content {
            json
          }
        }
      }
    `
  );

  return (
    <Layout pageTitle="cyfh-world-day">
      <SEO pageTitle="Παγκόσμια Ημέρα Ευαισθητοποίησης CyFH" />
      <PageHeader
        pageTitle="ΠΑΓΚΟΣΜΙΑ ΗΜΕΡΑ ΕΥΑΙΣΘΗΤΟΠΟΙΗΣΗΣ CyFH"
        pageDescription="Παγκόσμια Ημέρα Ευαισθητοποίησης για την Οικογενή Υπερχοληστερολαιμία και Κυπριακό Μητρώο Καταγραφής Ασθενών"
        page="Παγκόσμια Ημέρα Ευαισθητοποίησης CyFH"
        location={location}
        isCyFH={true}
      />

      <div className="page-wrapper">
        <RichTextRenderer richTextJson={cyfhWorldDay.content.json} />
      </div>
    </Layout>
  );
};

export default CyfhWorldDay;
